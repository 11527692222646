.HallOfFame {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;
}

@media only screen and (max-width: 1600px) {
    .HallOfFame {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media only screen and (max-width: 1000px) {
    .HallOfFame {
        grid-template-columns: repeat(1, 1fr);
    }
}

.Entry {
    overflow: hidden;
    position: relative;
    background: #000000;
    font-size: 16px;
    padding: 16px;
}

.Entry__Title {
    margin: 4px 0;
    text-shadow: 0 0 5px #ffffff;
    font-size: 30px;
    cursor: pointer;
    text-decoration: none;
    color: #ffffff;
    font-weight: bold;
}

.Entry__Image {
    width: 100%;
}

.Entry__Teams {
    list-style-type: none;
    padding-inline-start: 0;
}

.Entry__Teams > li {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 8px;
    position: relative;
    padding-left: 30px;
}

.Entry__Teams > li:before {
    content: '🥉';
    position: absolute;
    left: 0;
}

.Entry__Teams > li:first-child:before {
    content: '🥇';
}

.Entry__Teams > li:nth-child(2):before {
    content: '🥈';
}

.Entry__Players {
    font-weight: 400;
    font-size: 16px;
    padding-left: 0;
}

.Player:not(:last-child):after {
    content: ',';
    position: absolute;
}

.Player {
    display: inline-block;
    border-radius: 4px;
    margin-right: 6px;
    color: #aaa;
}

.Player--highlight {
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

@keyframes color {
    0% {
      background-color: #000;
      color: #aaa;
    }
    50% {
      background-color: #0072CE;
      color: #fff;
    }
    100% {
      background-color: #000;
      color: #aaa;
    }
  }