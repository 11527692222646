html {
  overflow-y: scroll;
}

.App {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 40px;
  box-sizing: border-box;
}

.App--front .App-logo {
  height: 40vmin;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  transition: height 0.5s;
}


.MainLinks a {
  color: #ffffff;
  text-decoration: none;
}

.MainLinks a:not(:last-child):after {
  content: ' |  ';
}

.MainLinks .turna {
  font-weight: bold;
  animation: color-change 0.5s infinite;
}

.cool-links {
  position: fixed;
  top: 24px;
  right: 24px;
}

.cool-links img {
  height: 64px;
  margin-right: 16px;
}

@keyframes color-change {
  0% { color: rgb(0, 195, 255); }
  25% { color: rgb(202, 36, 174); }
  50% { color: rgb(0, 255, 13); }
  75% { color: rgb(139, 34, 34); }
  100% { color: rgb(0, 195, 255); }
}